import React, { useEffect, useState } from "react"

import { Container } from "react-bootstrap"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Services() {
  const [tab, setTab] = useState("hmo-cleaning")

  useEffect(() => {
    if (window.location.href.split("#").length == 2) {
      setTab(window.location.href.split("#")[1])
    } else {
      setTab("hmo-cleaning")
    }
  })

  const query = useStaticQuery(graphql`
    query {
      sink: file(relativePath: { eq: "wipeSink.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      living: file(relativePath: { eq: "living-room.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bathroom: file(relativePath: { eq: "bathroomCrop.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout pageInfo={{ pageName: "services" }} homeBool="false">
      <SEO
        title="Services"
        keywords={[
          `Cleaning`,
          `Crewe`,
          `Lettings Cleaning`,
          `professional cleaning`,
          `MM Lettings Cleaning`,
          `HMO Cleaning`,
          `Short Stay Cleaning`,
          `End of tenancy`,
          `cleaning service`,
          `Nantwich`,
          `Cheshire East`,
        ]}
      />
      <Container>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${tab == "hmo-cleaning" ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#hmo-cleaning"
            >
              HMO Cleaning
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${tab == "short-stay" ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#short-stay"
            >
              Short Stay
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${tab == "end-of-tenancy" ? "active" : ""}`}
              data-bs-toggle="tab"
              href="#end-of-tenancy"
            >
              End of Tenancy
            </a>
          </li>
        </ul>
        <div id="myTabContent" className="tab-content">
          <div
            className={`tab-pane fade ${
              tab == "hmo-cleaning" ? "active show" : ""
            }`}
            id="hmo-cleaning"
          >
            <div className="row">
              <div className="col-md-6 xs-12">
                <Img fluid={query.living.childImageSharp.fluid} />
              </div>
              <div className="col-md-6 xs-12">
                <p className="mt-3">
                  A comprehensive clean spanning all <strong>bedrooms</strong>{" "}
                  and <strong>communal areas </strong>
                  of the residence
                </p>
                <br />
                <p>
                  This service ensures a <strong>spotless clean</strong>,
                  guaranteed to leave an impression on your next tenants.
                </p>
                <br />
                <p>
                  We want your clients to feel like they are right at home as
                  soon as they step through the door, and how better than with
                  <strong> fresh sheets</strong> which we take to the{" "}
                  <strong>dry cleaners</strong> ourselves.
                </p>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              tab == "short-stay" ? "active show" : ""
            }`}
            id="short-stay"
          >
            <div className="row">
              <div className="col-md-6 xs-12">
                <Img fluid={query.sink.childImageSharp.fluid} />
              </div>
              <div className="col-md-6 xs-12">
                <p className="mt-3">
                  Our signature <strong>sparkle clean</strong> designed for
                  short stay guests, ensuring a <strong>premium feel</strong>{" "}
                  for every stay.
                </p>
                <br />
                <p>
                  This entails making every detail feel{" "}
                  <strong>like new</strong>, from{" "}
                  <strong>washing glassware</strong> to{" "}
                  <strong>polishing fixtures</strong>, and cleaning every
                  surface to a <strong>brilliant shine</strong>.
                </p>
                <br />
                <p>
                  We pride ourselves on our attention to detail, and double
                  check that every room is cleaned to our{" "}
                  <strong>high standards</strong> and with{" "}
                  <strong> fresh sheets</strong>, ready to welcome your tenants
                  for their stay.
                </p>
              </div>
            </div>
          </div>
          <div
            className={`tab-pane fade ${
              tab == "end-of-tenancy" ? "active show" : ""
            }`}
            id="end-of-tenancy"
          >
            <div className="row">
              <div className="col-md-6 xs-12">
                <Img fluid={query.bathroom.childImageSharp.fluid} />
              </div>
              <div className="col-md-6 xs-12">
                <p className="mt-3">
                  An extensive clean for a top-to-bottom refresh of the{" "}
                  <strong>whole property.</strong>{" "}
                </p>
                <br />
                <p>
                  Tenants moving out can be a a hassle, allow us to alleviate
                  the stress with a <strong>thorough clean</strong> of every
                  inch of the proerty.
                </p>
                <br />
                <p>
                  You can expect us to take care of any leftover{" "}
                  <strong>rubbish</strong> and
                  <strong> food</strong>, refreshing the{" "}
                  <strong>appliances</strong> and <strong>fridge</strong>, as
                  well as
                  <strong> disenfecting surfaces</strong>.
                </p>
                <br />
                <p>
                  Let us leave you with a <strong>pristine</strong> blank-slate
                  for yourself and your new tenants.
                </p>
              </div>
            </div>
          </div>
        </div>
        <hr></hr>
        <p className="text-center">
          Additional services such as oven and carpet deep-cleans are provided
          on request.
        </p>
        <p className="text-center">
          Above is not an exhastative list, as at MM Lettings Cleaning we aim to
          tailor our services to each of our clients, so please do not hesitate
          to contact us for a quotation.
        </p>
      </Container>
    </Layout>
  )
}
